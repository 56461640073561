
.ck-editor__editable {
  min-height: 300px;
  max-height: 400px;
  overflow: auto;
}
.ck.ck-editor__editable_inline{
  border: 1px solid rgb(0,0,0,0.2) !important;
}

.ck.ck-editor__editable_inline:focus {
  border: var(--ck-focus-ring) !important;
}

.doughnut-title {
  text-align: center;
  font-weight: bold;
}